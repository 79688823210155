<template>
  <div>
    <custom-breadcrumb
      pageTitle="Partners"
      :breadcrumb="breadcrumb"
    ></custom-breadcrumb>
    <!-- ADD NEW -->
    <add-new
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      @refetch-data="getData"
    />
    <b-card no-body>
      
      <!-- FILTROS -->
      <filters
        ref="filter"
        context="partners"
        @set-data="setData"
        @set-loading-data="setLoadingData"
      /> 

      <!-- TABLA -->
      <paginated-table
        :data="partners"
        ability="Partners"
        link='partners'
        :canDelete="false"
        :canRecover="false"
        :canSelect="false"
        :canDownload="true"
        :loading_data="loading_data"

        :buttonsOptions="buttonsOptions"
        @create="isAddNewSidebarActive = true"
      ></paginated-table>
        <!-- @manage-selected-rows="manageSelectedRows"
        @delete="deleteModel"
        @recover="recoverModel" -->
    </b-card>
  </div>
</template>

<script>
import PaginatedTable from '@/views/sl-components/PaginatedTable.vue'
import Filters from '@/views/sl-components/Filters.vue'
import {
  BCard, 
} from 'bootstrap-vue'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import AddNew from './AddNew.vue'

export default {
  components:{
    BCard, 
    
    PaginatedTable,
    Filters,
    CustomBreadcrumb,
    AddNew,
    
  },
  data(){
    return {
      partners: [],
      selected: [],
      loading_data: false,

      isAddNewSidebarActive: false,
      breadcrumb: [],

    }
  },
  computed: {
      buttonsOptions(){
        let opt = [
          {
            action: "create",
            variant: "success",
            icon: "PlusIcon",
            name: this.$t('forms.add_model', {model: this.$t('models.partner')})
          }
        ]
        return opt
      },
    },
  methods: {
    manageSelectedRows(selected_rows){
      //console.log(selected_rows, "selected_rows")
      this.selected = selected_rows
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    setLoadingData(loading){
      this.loading_data = loading
    },
    setData(data){
      console.log("set data", data)
        if(data.partners.length > 40000){
            this.loading_data = false
            this.partners = []
            this.fetch_message = 'The data cannot be displayed because the query returned too many records. Please try filtering your data.'
        } else {
            this.loading_data = false
            this.fetch_message = ''
            this.partners = data.partners;
        }
    },
    async getData(){
        
        await this.$http.post('partners',{}).then( response =>{
          console.log(response)
          this.partners = response.data.partners;
          
          this.breadcrumb = [
            {
              text: 'Partners',
              active: true,
            },
          ]


        }).catch(error => {
          console.log("Err -> ", error)
      })
    },
  },
  mounted(){
        this.getData()
  }
}
</script>

<style>

</style>